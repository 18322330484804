@import './components/css/VAR.scss';
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700&display=swap');

* {
  box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
  font-family: 'Nunito Sans', sans-serif !important;
}

body {
  font-family: 'Nunito Sans', sans-serif;
  background-color: $contentBG;
  color: $darkP;
}

.App {
  text-align: center;
}

.App a {
  color: green;
  margin: 20px;
}

#main-content-container {
  padding: 25px;
}

#sidenav-and-content-wrapper #main-content-container {
  flex: 10;
  max-height: calc(100vh - 100px);
  overflow-y: scroll;
}

progress[value] {
  -webkit-appearance: none;
  height: 7px;
}

progress[value]::-webkit-progress-bar {
  background-color: #ccc;
  border-radius: 15px;
}

progress[value]::-webkit-progress-value {
  background-color: #fcdb00;
  border-radius: 15px;
}

::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
