/* Primary / Main Colors */
$yellowP: #fcdb00;
$blueP: #8bbbe4;
$darkP: #333333;
$blackP: #000000;
$whiteP: #ffffff;
$greyP: #aeada8;
$beigeP: #fcf1eb;
$sidebarP: #2d3347;
$contentBG: #f1f3f6;

/* Secondary / Washed-out Colors */
$lightGrey: #d8d8d8;

$yellow: #fff2a9;
$yellow2: #fff8d0;
$yellow3: #f1eac8;

$blue: #dbe9ff;
$blue2: #dff3ff;

$green: #c7e26a;
$green2: #f2fdce;
$green3: #dbfff0;

$red: #f56c76;
$red2: #ee9a9a;
$red3: #ffd5d8;
$red4: #ffdeed;

$purple: #f8e9ff;
$orange: #ffdfc8;

/* DataBanner Colors */

$blueDB: #8bbbe4;
$blueDarkDB: #73aee1;

$greenDB: #c7e26a;
$greenDarkDB: #b8d160;

$redDB: #f56c76;
$redDarkDB: #e8636d;
